<template>
  <div id="gtag" v-if="cookies.status==null">
    <div class="gt-frame">
      <div v-if="language=='DE'">
        Diese Seite nutzt Cookies und Tracking-Technologien von Dritten. Dies ermöglicht es uns, die Funktionalität und Performance der Webseite zu verbessern und Ihnen damit ein besseres Internet-Erlebnis zu bieten. Wenn Sie auf „Akzeptieren“ klicken, erklären Sie sich damit einverstanden, dass wir Ihre Daten verwenden und diese Daten auch außerhalb der EU, insb. in den USA, verarbeitet werden. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen oder ändern.
        <div class="gt-buttons-container">
          <img alt="Cookies Ablehnen" 
            width="310" height="71"   
            :src="imagePath + 'cookies/decline-cookies.' + this.language+'.png?'+updateView" 
            @click="updateCookies(false)"/>
          <img alt="Cookies Akzeptieren"  
            width="371" height="71"  
            :src="imagePath + 'cookies/accept-cookies.'  + this.language+'.png?'+updateView" 
            @click="updateCookies(true)"/>
        </div>
      </div>
      <div v-if="language=='FR'">
        Nous utilisons des cookies pour optimiser votre visite sur notre site web. En poursuivant votre navigation vous acceptez l'utilisation de cookies selon notre déclaration de protection des données
        <div class="gt-buttons-container">
          <img alt="JE REFUSE" :src="imagePath + 'cookies/decline-cookies.' + this.language+'.png?'+updateView" @click="updateCookies(false)"/>
          <img alt="J'ACCEPTE" :src="imagePath + 'cookies/accept-cookies.'  + this.language+'.png?'+updateView" @click="updateCookies(true)"/>
        </div>
      </div>
    </div>
  </div>
  <div id="gtagplacer" v-if="cookies.status==null"></div>
</template>

<style src="../../../components/parts/cookies/style.css"></style>

<script>
import { mapState, mapActions } from 'vuex';
import Cookies from 'js-cookie';
import { initGTM } from "../../../plugins/gtag"; 

export default {
  data() {
      return {
        visible: true,
        cookieName: 'WebsiteCookie',
      }
  },
  computed: {
    ...mapState(['imagePath',  'updateView', 'language', 'cookies']),
  },
  mounted() {
    this.checkCookie();
    setTimeout(() => {
      
      const store = this.$store;
      let gtagID;

      // GOOGLE TAG ID according to ENV & COUNTRY
      gtagID = (store.state.currentEnvironment==='prod' && store.state.country==='DE')? store.state.gtag.DE:null;
      gtagID = (store.state.currentEnvironment==='prod' && store.state.country==='AT')? store.state.gtag.DE:gtagID;
      gtagID = (store.state.currentEnvironment==='prod' && store.state.country==='FR')? store.state.gtag.FR:gtagID;

      // GOOGLE TAG ID according to TEST
      gtagID = (store.state.currentEnvironment==='test')? store.state.gtag.TEST:gtagID;
    
      initGTM(gtagID);

      //this.loadGoogleTag();
    }, 1250);
  },
  methods: {
    ...mapActions(['updCookiesStatus']),
    updateCookies(result) {
      this.updCookiesStatus(result)
      this.setCookieValue(this.cookieName, (result==true)? 'TRUE':'FALSE');
      location.reload();
    },
    checkCookie() {
      const existingValue = Cookies.get(this.cookieName);

      if (existingValue) {
        this.updCookiesStatus((existingValue=='TRUE')? true:false);
      }
    },
    // Function to set a cookie
    setCookieValue(cookieName, cookieValue) {
      Cookies.set(cookieName, cookieValue, { expires: this.cookies.days, path: '/' });
    },

    // Function to clear a cookie
    clearCookieValue() {
      Cookies.remove(cookieName, { path: '/' });
      this.updCookiesStatus(null)
    },
    loadGoogleTag() {
      const store = this.$store; // Assuming you are using Vuex for state management

      if(!store.state.cookies.status) return;

      let gtagID;

      // GOOGLE TAG ID according to ENV & COUNTRY
      gtagID = (store.state.currentEnvironment==='prod' && store.state.country==='DE')? store.state.gtag.DE:null;
      gtagID = (store.state.currentEnvironment==='prod' && store.state.country==='AT')? store.state.gtag.DE:gtagID;
      gtagID = (store.state.currentEnvironment==='prod' && store.state.country==='FR')? store.state.gtag.FR:gtagID;

      // GOOGLE TAG ID according to TEST
      gtagID = (store.state.currentEnvironment==='test')? store.state.gtag.TEST:gtagID;

      // Create a script element for the chosen Google Tag
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`;

      // Append the script to the document head
      document.head.appendChild(script);

      // Set up gtag.js configuration
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', gtagID);
      };
    },
  },
}
</script>