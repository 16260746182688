<template>
    <div id="step05">
        <div class="inner lightgraybkg" ref="scrollContainer"> 
            <div class="d-floatleft">
                <div class="d-content form form01">
                    <div class="title">
                        DONNÉES DE L'ENTREPRISE
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(1)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companyname }}
                        </div>
                        <span>Nom de l'entreprise *</span>    
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companystreetnumber }} 
                        </div>
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companypostcity }}
                        </div>
                        <div class="p-inputtext p-component">
                            FRANCE
                        </div>
                        <span>Pays (siège social)</span>
                    </div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companyregister }}
                        </div>
                        <span>Numéro de RCS</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companytax}}
                        </div>
                        <span>N° de TVA intracommunautaire</span>
                    </div>
                    <div class="d-floatclear"></div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companylegalform }}
                        </div>
                        <span>Forme juridique</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companyemployees }}
                        </div>
                        <span>Nombre d'employés</span>
                    </div>
                    <div class="d-floatclear"></div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step01.companyannualsales }}
                        </div>
                        <span>{{ contractSteps.step01.companyselectionText }}</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ 
                                formatDate(contractSteps.step01.companyfrom)
                                +'-'+ 
                                formatDate(contractSteps.step01.companyto)
                            }}
                        </div>
                        <span>Exercice financier</span>
                    </div>
                    <div class="d-floatclear"></div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step01.companyselection }}
                        </div>
                        <span>Catégorie de frais</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ 
                                contractSteps.step01.yearlyCost
                            }}
                        </div>
                        <span>Votre cotisation annuelle</span>
                    </div>
                    <div class="d-floatclear"></div>
                
                </div>
            </div>
            <div class="d-floatright">
                <div class="d-content form form02">
                    <div class="title">
                        CONTACT
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(3)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contacttitle }}
                        </div>
                        <span>Civilité</span>    
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contactfirstname }}
                        </div>
                        <span>Prénom</span>    
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{  contractSteps.step03.contactfamilyname }}
                        </div>
                        <span>Nom</span>    
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.companyemail }}
                        </div>
                        <span>E-Mail (professionnel)</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.companymobile }}
                        </div>
                        <span>Portable (professionnel)</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contactemail }}
                        </div>
                        <span>E-Mail (privé – urgences)</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contactmobile }}
                        </div>
                        <span>Portable (privé – urgences)</span>
                    </div>

                </div>
            </div>
            <div class="d-floatleft">
                <div class="d-content form form03" >
                    <div class="title">
                        MODE DE PAIEMENT
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(4)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.paymentmethod }}
                        </div>
                    </div>
                    <div class="p-width100" v-if="contractSteps.step04.paymentmethod==='Prélèvement automatique'">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.paymentiban }}
                        </div>
                        <span>IBAN</span>
                    </div>
                    <div class="p-width100" v-if="contractSteps.step04.paymentmethod==='Prélèvement automatique'">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.paymentbic }}
                        </div>
                        <span>BIC</span>
                    </div>
                </div>
            </div>
            <div class="d-floatright">
                <div class="d-content form form04">
                    <div class="title">
                        VENDU PAR  
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(4)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100"  
                        v-if="contractSteps.step04.partnermediator">
                        <div class="p-inputtext p-component">
                            Intermédiaire du distributeur de COGITANDA®
                        </div>
                    </div>
                    <div class="p-width100"  
                        v-if="contractSteps.step04.partnermediator">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.partnername }}
                        </div>
                        <span>distributeur de COGITANDA®</span>
                    </div>
                    <div class="p-width100"  
                        v-if="contractSteps.step04.partnermediator">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.partnernumber }}
                        </div>
                        <span>Numéro de partenaire COGITANDA®</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component" 
                            v-if="!contractSteps.step04.partnermediator">
                            Sans intermédiaire du distributeur COGITANDA®
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-floatclear form" style="padding: 0!important; height: 1px!important"> </div>
            <div class="w-content">
                <strong>INFORMATION SUR LA PROTECTION DES DONNEES : </strong> les données personnelles collectées ici, 
                qui sont nécessaires et requises dans le seul but de mettre en œuvre l’Aide d’Urgence COGITANDA®, sont 
                collectées et traitées par COGITANDA® sur la base d'autorisations légales (article 6, paragraphe 1 du RGPD) 
                (voir également la déclaration de protection des données de COGITANDA® sur 
                <a href="https://www.cogitanda.com/fr/" target="_blank">wwww.cogitanda.com/fr</a> ).
            </div>
            <div class="w-colcontent" @click="handleContractConfirm()">
                <div class="w-contentL ">
                    <img class="check" :src="imagePath + 'contractform/check.'+((this.stepData.contractconfirmation)? 'active':'inactive')+'.png?'+updateView"/> 
                </div>
                <div class="w-contentR" :class="{'fontError': stepData.contractconfirmation === false && stepData.contractConfirmationError === true}">
                    <span>
                        En cochant cette case, vous confirmez qu'aucune information sur une cyberattaque en cours concernant le risque susmentionné n'est actuellement connue.
                    </span>
                </div>
            </div>   
            <div class="w-colcontent" @click="handleContractAccept()">
                <div class="w-contentL">
                    <img class="check" :src="imagePath + 'contractform/check.'+((this.stepData.contractaccept)? 'active':'inactive')+'.png?'+updateView"/> 
                </div>
                <div class="w-contentR" :class="{'fontError': stepData.contractaccept === false && stepData.contractacceptError === true}">
                    <span>
                        <strong>AVIS DE CONCLUSION DE CONTRAT :</strong> En cochant la case et en 
                        transmettant le document à COGITANDA Claims Services GmbH, l'acceptaton du 
                        présent contrat de services est juridiquement contraignante.
                    </span>
                </div>
            </div>   
        </div>
        
        <div class="d-floatclear form">
            <div  class="p-width90R"
                id="acceptcontractform">
                <div class="d-floatclear form" style="margin-bottom: 0!important;padding-top: 0!important;">
                    <div class="contractsign">
                        <span>
                            
                        </span>
                    </div>
                </div>
            </div>
            <div class="clearfloat">&nbsp;</div>
            
        </div>

    </div>
</template>

<style src="../../../../components/tools/contractform/FR/step05.css"></style>
  
<script>
import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step05',
            stepData: {
                contractconfirmation: false,
                contractConfirmationError: false,
                contractaccept: false,
                contractacceptError: false,
                sendcontract: false,
                editButton: true
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        formatDate(date) {
            return date.toLocaleDateString('de-DE');
        },
        editForm(stepNumber) {
            this.$emit('stepNumber', stepNumber);
        },
        handleContractConfirm() {
            this.stepData.contractconfirmation = !this.stepData.contractconfirmation;
        },
        handleContractAccept() {
            this.stepData.contractaccept = !this.stepData.contractaccept;
        },
        scrollDown() {
            const scrollContainer = this.$refs.scrollContainer;
            if(scrollContainer) {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step05;
                this.scrollDown();
            }
        },
    },
    mounted() {
        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step05: this.stepData});

        } else {
            this.stepData = this.contractSteps.step05;
        }
    }
}
</script>