import './assets/css/style.css';
import 'primevue/resources/themes/saga-blue/theme.css'; // Choose a theme from PrimeVue themes
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import store from './components/tools/store.js';

import App from './App.vue';
import router from './router';

const app = createApp(App);

const redirectFromHash = () => {
    const hash = window.location.hash;
    if (hash === '#cyberfacts') {
      router.push('/cyberfacts');
    }
 };
  
window.addEventListener('load', redirectFromHash);

app.use(store);
app.use(router);
app.use(PrimeVue);
app.mount('#app');