<template>
    <div id="step05">
        <div class="inner lightgraybkg" ref="scrollContainer"> 
            <div class="d-floatleft">
                <div class="d-content form form01">
                    <div class="title">
                        FIRMENDATEN
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(1)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companyname }}
                        </div>
                        <span>Firmenname</span>    
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companystreetnumber }} 
                        </div>
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companypostcity }}
                        </div>
                        <div class="p-inputtext p-component">
                            {{ (country=='DE')? 'DEUTSCHLAND':'ÖSTERREICH' }}
                        </div>
                        <span>Firmensitz</span>
                    </div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companyregister }}
                        </div>
                        <span v-if="country=='AT'">Firmenbuchnummer</span>
                        <span v-else>Handelsregister-Nr.</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companytax}}
                        </div>
                        <span v-if="country=='AT'">UID-Nummer</span>
                        <span v-else>Umsatzsteuer-ID</span>
                    </div>
                    <div class="d-floatclear"></div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companylegalform }}
                        </div>
                        <span>Rechtsform.</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step02.companyemployees }}
                        </div>
                        <span>Mitarbeiterzahl</span>
                    </div>
                    <div class="d-floatclear"></div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step01.companyannualsales }}
                        </div>
                        <span>{{ contractSteps.step01.companyselectionText }}</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ 
                                formatDate(contractSteps.step01.companyfrom)
                                +'-'+ 
                                formatDate(contractSteps.step01.companyto)
                            }}
                        </div>
                        <span>Geschäftsjahr</span>
                    </div>
                    <div class="d-floatclear"></div>
                    <div class="p-width50L" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step01.companyselection }}
                        </div>
                        <span>Gebühren-Kategorie</span>
                    </div>
                    <div class="p-width50R" >
                        <div class="p-inputtext p-component">
                            {{ 
                                contractSteps.step01.yearlyCost
                            }}
                        </div>
                        <span>Ihre Jahresgebühr</span>
                    </div>
                    <div class="d-floatclear"></div>
                
                </div>
            </div>
            <div class="d-floatright">
                <div class="d-content form form02">
                    <div class="title">
                        ANSPRECHPARTNER
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(3)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contacttitle }}
                        </div>
                        <span>Anrede</span>    
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contactfirstname }}
                        </div>
                        <span>Vorname und Name</span>    
                    </div>
                    <div class="p-width100">
                        <div class="p-inputtext p-component">
                            {{  contractSteps.step03.contactfamilyname }}
                        </div>
                        <span>Name</span>    
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.companyemail }}
                        </div>
                        <span>E-Mail (geschäftlich)</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.companymobile }}
                        </div>
                        <span>Mobil (geschäftlich)</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contactemail }}
                        </div>
                        <span>E-Mail (privat – Notfälle)</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step03.contactmobile }}
                        </div>
                        <span>Mobil (privat – Notfälle)</span>
                    </div>

                </div>
            </div>
            <div class="d-floatleft">
                <div class="d-content form form03" >
                    <div class="title">
                        ZAHLUNGSWEISE
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(4)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.paymentmethod }}
                        </div>
                        <span>Zahlungsmethode</span>
                    </div>
                    <div class="p-width100" v-if="contractSteps.step04.paymentmethod==='LASTSCHRIFT'">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.paymentiban }}
                        </div>
                        <span>IBAN</span>
                    </div>
                    <div class="p-width100" v-if="contractSteps.step04.paymentmethod==='LASTSCHRIFT'">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.paymentbic }}
                        </div>
                        <span>BIC</span>
                    </div>
                </div>
            </div>
            <div class="d-floatright">
                <div class="d-content form form04">
                    <div class="title">
                        VERMITTELT DURCH  
                        <img class="check" :src="imagePath + 'contractform/edit.png?'+updateView" @click="editForm(4)" v-if="stepData.editButton"/>
                    </div>
                    <div class="p-width100"  
                        v-if="contractSteps.step04.partnermediator">
                        <div class="p-inputtext p-component">
                            COGITANDA®-Vertriebspartner
                        </div>
                    </div>
                    <div class="p-width100"  
                        v-if="contractSteps.step04.partnermediator">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.partnername }}
                        </div>
                        <span>Name des COGITANDA®-Vertriebspartner</span>
                    </div>
                    <div class="p-width100"  
                        v-if="contractSteps.step04.partnermediator">
                        <div class="p-inputtext p-component">
                            {{ contractSteps.step04.partnernumber }}
                        </div>
                        <span>COGITANDA®-Partnernummer</span>
                    </div>
                    <div class="p-width100" >
                        <div class="p-inputtext p-component" 
                            v-if="!contractSteps.step04.partnermediator">
                            Keine Vermittlung duch COGITANDA®-Vertriebspartner
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-floatclear form" style="padding: 0!important; height: 1px!important"> </div>
            <div class="w-content">
                <strong>HINWEIS DATENSCHUTZ:</strong> Die hier erhobenen personenbezogenen Daten, die allein zum Zwecke der Durchführung der COGITANDA® 
                Cyber Sofort Hilfe notwendig und erforderlich sind, werden von der COGITANDA® auf Grundlage gesetzlicher Berechtigungen erhoben 
                (Art. 6 Abs. 1 DSGVO) und verarbeitet (siehe auch die COGITANDA® Datenschutzerklärung unter 
                <a href="https://www.cogitanda.com/" target="_blank">wwww.cogitanda.com</a>)
            </div>
            <div class="w-colcontent" @click="handleContractConfirm()">
                <div class="w-contentL ">
                    <img class="check" :src="imagePath + 'contractform/check.'+((this.stepData.contractconfirmation)? 'active':'inactive')+'.png?'+updateView"/> 
                </div>
                <div class="w-contentR" :class="{'fontError': stepData.contractconfirmation === false && stepData.contractConfirmationError === true}">
                    <span>
                        Mit ser Setzung des Hakens wird bestätigt, dass derzeit keine Informationen über einen laufenden Cyberangriff auf das oben genannte Risiko bekannt sind.
                    </span>
                </div>
            </div>   
            <div class="w-colcontent" @click="handleContractAccept()">
                <div class="w-contentL">
                    <img class="check" :src="imagePath + 'contractform/check.'+((this.stepData.contractaccept)? 'active':'inactive')+'.png?'+updateView"/> 
                </div>
                <div class="w-contentR" :class="{'fontError': stepData.contractaccept === false && stepData.contractacceptError === true}">
                    <span>
                    <strong>HINWEIS VERTRAGSABSCHLUSS:</strong> Mit der Setzung des Hakens und Übermittlung des Dokuments an die COGITANDA Claims 
                        Services GmbH erfolgt rechtsverbindlich die Annahme dieses Dienstleistungsvertrages.
                    </span>
                </div>
            </div>   
        </div>
        
        <div class="d-floatclear form">
            <div  class="p-width90R"
                id="acceptcontractform">
                <div class="d-floatclear form" style="margin-bottom: 0!important;padding-top: 0!important;">
                    <div class="contractsign">
                        <span>
                            
                        </span>
                    </div>
                </div>
            </div>
            <div class="clearfloat">&nbsp;</div>
            
        </div>

    </div>
</template>

<style src="../../../../components/tools/contractform/DE/step05.css"></style>
  
<script>
import { mapState, mapActions } from 'vuex'; 

export default {
    data() {
        return {
            stepName: 'step05',
            stepData: {
                contractconfirmation: false,
                contractConfirmationError: false,
                contractaccept: false,
                contractacceptError: false,
                sendcontract: false,
                editButton: true
            }
        }
    },
    computed: {
        ...mapState(['imagePath', 'updateView', 'country', 'contractSteps']),
    },
    methods: {
        ...mapActions(['updateContractSteps']),
        changeContractSteps(newContractSteps) {
            this.$store.dispatch('updateContractSteps', newContractSteps);
        },
        formatDate(date) {
            return date.toLocaleDateString('de-DE');
        },
        editForm(stepNumber) {
            this.$emit('stepNumber', stepNumber);
        },
        handleContractConfirm() {
            this.stepData.contractconfirmation = !this.stepData.contractconfirmation;
        },
        handleContractAccept() {
            this.stepData.contractaccept = !this.stepData.contractaccept;
        },
        scrollDown() {
            const scrollContainer = this.$refs.scrollContainer;
            if(scrollContainer) {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
    },
    watch: {
        'contractSteps': {
            handler(newdata) {
                this.stepData = newdata.step05;
                this.scrollDown();
            }
        },
    },
    mounted() {
        if(!this.contractSteps.hasOwnProperty(this.stepName)){

            this.stepData = {...this.stepData};
            this.changeContractSteps({step05: this.stepData});

        } else {
            this.stepData = this.contractSteps.step05;
        }
    }
}
</script>